import React from "react"
import PropTypes from "prop-types"
import { FiInfo } from "react-icons/fi";
import moment from 'moment'

const Shop = ({shopId}) => {
	let shops = {
		'rakuten'	:[],
		'amazon'	:[],
		'akachan'	:[],
		'lohaco'	:[],
		'yahoo'	:[],
		'aupay'	:[],
		'babiesrus'	:[],
		'nishimatsuya'	:[],
		'pg'	:[],
		'costco'	:[],
		'tsuruha'	:[],
	}
	let _shops = {
		'rakuten'	:[],
		'amazon'	:[],
		'akachan'	:[],
		'lohaco'	:[],
		'yahoo'	:[],
		'aupay'	:[],
		'babiesrus'	:[],
		'nishimatsuya'	:[],
		'pg'	:[],
		'costco'	:[],
		'tsuruha'	:[],
	}

	const campaigns = [
		// 楽天
		{start:'2024-10-01 00:00',end:'2024-10-01 23:59',shop:'rakuten',msg:'＋2倍ポイント(ワンダフルデー)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2F'},
		{start:'2024-10-01 00:00',end:'2024-10-01 23:59',shop:'rakuten',msg:'10%OFFクーポン(おむつ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fbrand%2F'},
		{start:'2024-10-01 00:00',end:'2024-10-01 23:59',shop:'rakuten',msg:'15%OFFクーポン(日用品)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fwonderfulday%2Fbrand%2F'},
		// {start:'2024-02-28 10:00',end:'2024-03-02 09:59',shop:'rakuten',msg:'スーパーDEALポイント還元(パンパース)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2F%25E3%2583%2591%25E3%2583%25B3%25E3%2583%2591%25E3%2583%25BC%25E3%2582%25B9%2F%3Ff%3D13%26l-id%3DEvent_PC_RSD_top_search'},
		// {start:'2024-04-08 10:00',end:'2024-09-11 23:59',shop:'rakuten',msg:'10%OFFクーポン(対象商品2個以上購入)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2F24.rakuten.co.jp%2Fbaby%2Fspecial%2F6528_107164%2F'},
		// {start:'2024-04-08 10:00',end:'2024-10-10 09:59',shop:'rakuten',msg:'15%OFFクーポン(対象商品5個以上購入)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe1b697ee3bb1d62b6acf90f84d7f7996a18273c00507e5be%2F'},
		// {start:'2024-02-28 10:00',end:'2024-03-13 09:59',shop:'rakuten',msg:'スーパーDEALポイント還元(ユニ・チャーム)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fsuperdeal%2Ffeature%2Fbigdeal%2F240228_unicharm%2F%3Fl-id%3Dhld_nbw_rimp_423'},
		// {start:'2024-02-28 10:00',end:'2024-03-15 09:59',shop:'rakuten',msg:'スーパーDEALポイント還元(グーン)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2F%25E3%2582%25B0%25E3%2583%25BC%25E3%2583%25B3%2F100533%2F%3Ff%3D13'},
		// {start:'2024-09-02 20:00',end:'2024-09-04 19:59',shop:'rakuten',msg:'SALE商品先行購入(楽天スーパーSALE先行SALE)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2Fmobile%2Fpresale%2F'},
		// {start:'2024-09-03 20:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'＋9倍ポイント(楽天スーパーSALE)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2F'},
		// {start:'2024-09-03 20:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'＋1倍ポイント(ママ割)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Ffamily%2Fsupersale%2F'},
		// {start:'2024-09-03 20:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'＋1倍ポイント(39ショップ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fguide%2Ffreeshippingline%2Fcampaign%2F'},
		// {start:'2024-09-08 00:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'200円OFFクーポン(楽天スーパーSALE)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2F20240904dsysc%2F200coupon%2F'},
		// {start:'2024-09-09 00:00',end:'2024-09-10 23:59',shop:'rakuten',msg:'1,000円OFFクーポン(ベビーザらス)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fwww.rakuten.co.jp%2Ftoysrus%2F'},
		// {start:'2024-09-09 00:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'50%OFFクーポン(Babycare)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fa0f63ca92545b2670143d8c70da103d8af64a3747b5d6ac5%2F'},
		// {start:'2024-05-08 20:00',end:'2024-05-16 01:59',shop:'rakuten',msg:'最大50%ポイントバック',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240509fwunh%2Fbulk%2F'},
		// {start:'2024-05-08 20:00',end:'2024-05-16 01:59',shop:'rakuten',msg:'100円OFF/300円OFFクーポン',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240509fwunh%2Fbulk%2F'},
		// {start:'2024-03-06 17:00',end:'2024-03-06 17:59',shop:'rakuten',msg:'半額セール(ネピアGenki！パンツL)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fitem.rakuten.co.jp%2Ftsukasa1%2F10000284cp%2F'},
		{start:'2024-09-20 00:00',end:'2024-09-20 23:59',shop:'rakuten',msg:'＋3倍ポイント(0のつく日)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fcard%2Fpointday%2F'},
		{start:'2024-09-16 00:00',end:'2024-09-17 23:59',shop:'rakuten',msg:'＋1倍ポイント(勝ったら倍)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsports%2F'},
		// {start:'2024-02-28 00:00',end:'2024-08-26 23:59',shop:'rakuten',msg:'＋2倍ポイント(W勝利!勝ったら倍)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsports%2F'},
		{start:'2024-09-25 00:00',end:'2024-09-25 23:59',shop:'rakuten',msg:'＋3倍ポイント(5のつく日)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fcard%2Fpointday%2F'},
		// {start:'2024-02-28 00:00',end:'2024-04-21 23:59',shop:'rakuten',msg:'＋2倍ポイント(2ショップ購入)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2F2shop3%2F'},
		{start:'2024-05-16 10:00',end:'2024-09-26 23:59',shop:'rakuten',msg:'スーパーDEALポイント還元',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2F-%2F213972%2F%3Ff%3D13'},
		{start:'2024-09-18 10:00',end:'2024-09-21 09:59',shop:'rakuten',msg:'＋6倍ポイント(育児の日)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fbaby%2F192campaign%2F'},
		// {start:'2024-08-17 10:00',end:'2024-08-21 09:59',shop:'rakuten',msg:'育児の日連動クーポン',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2F24.rakuten.co.jp%2Fbaby%2Fcampaign%2Fikujinohi_coupon%2F'},
		// {start:'2024-08-17 10:00',end:'2024-08-21 09:59',shop:'rakuten',msg:'1,000円OFFクーポン(育児の日)',url:'https://hb.afl.rakuten.co.jp/hgc/1234624c.d2a40d30.1234624d.00b6e9c6/?pc=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fb600e739eaa5ec0ee8b3a14f4749682c2d6b0fcb74949956%2F&link_type=text&ut=eyJwYWdlIjoidXJsIiwidHlwZSI6InRleHQiLCJjb2wiOjF9'},
		// {start:'2024-08-17 10:00',end:'2024-08-21 09:59',shop:'rakuten',msg:'10%OFFクーポン(育児の日)',url:'https://hb.afl.rakuten.co.jp/hgc/1234624c.d2a40d30.1234624d.00b6e9c6/?pc=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F452e3bb6ab9f4285fc6b549ffa926dbe088d971c6d79958b%2F%3F%26page%3D2%23search&link_type=text&ut=eyJwYWdlIjoidXJsIiwidHlwZSI6InRleHQiLCJjb2wiOjF9'},
		// {start:'2024-08-17 10:00',end:'2024-08-21 09:59',shop:'rakuten',msg:'20%OFFクーポン(育児の日)',url:'https://hb.afl.rakuten.co.jp/hgc/1234624c.d2a40d30.1234624d.00b6e9c6/?pc=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F430ed849e4dfe19f7379cfdb13edc812d2f0a589d044ff4c%2F&link_type=text&ut=eyJwYWdlIjoidXJsIiwidHlwZSI6InRleHQiLCJjb2wiOjF9'},
		// {start:'2024-08-17 10:00',end:'2024-08-21 09:59',shop:'rakuten',msg:'30%OFFクーポン(育児の日)',url:'https://hb.afl.rakuten.co.jp/hgc/1234624c.d2a40d30.1234624d.00b6e9c6/?pc=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F04904e8f81a514814a0cc0c61bd67364a9812f833c00a4ab%2F&link_type=text&ut=eyJwYWdlIjoidXJsIiwidHlwZSI6InRleHQiLCJjb2wiOjF9'},
		{start:'2024-09-18 00:00',end:'2024-09-18 23:59',shop:'rakuten',msg:'＋3倍ポイント(いちばの日 ダイヤモンド会員)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Frank%2Fpoint%2F'},
		// {start:'2024-08-23 20:00',end:'2024-08-27 09:59',shop:'rakuten',msg:'＋9倍ポイント(お買い物マラソン)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F'},
		// {start:'2024-08-23 20:00',end:'2024-08-27 09:59',shop:'rakuten',msg:'＋1倍ポイント(ママ割)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Ffamily%2Fmarathon%2F'},
		// {start:'2024-08-23 20:00',end:'2024-08-27 09:59',shop:'rakuten',msg:'1,000円OFFクーポン(ベイシア)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F86cf38812c30d7e3d7718b7a92260e78563375af56a19ddd%2F'},
		// {start:'2024-08-23 20:00',end:'2024-08-27 09:59',shop:'rakuten',msg:'200円OFFクーポン(ベイシア)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F339e05d9d2f60407474deeced5ba9f256aa13f17f40cc50c%2F'},
		// {start:'2024-09-04 10:00',end:'2024-09-05 23:59',shop:'rakuten',msg:'5%OFFクーポン(レック)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fbf9d421798fae0d9cb60e827dd73a673e97428f5586ecffe%2F'},
		// {start:'2024-03-21 20:00',end:'2024-09-11 01:59',shop:'rakuten',msg:'＋1倍ポイント(ポイントアップ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fsupersale%2F20240904dsysc%2Fpointdouble%2F'},
		// {start:'2024-04-01 00:00',end:'2024-04-15 23:59',shop:'rakuten',msg:'スーパーDEAL＋1%ポイント還元',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fsuperdeal%2Fcampaign%2Frakutencard%2F'},
		// {start:'2024-04-01 00:00',end:'2024-04-15 23:59',shop:'rakuten',msg:'＋1倍ポイント(リピート購入)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fcampaign%2Fpoint-up%2Fmarathon%2F20240414thncc%2Fhistory%2F'},
		// {start:'2024-05-16 00:00',end:'2024-05-28 00:00',shop:'rakuten',msg:'5%OFFクーポン(パンパース)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F3e14964f5e71989071bb7d13386a98bec7218fa5f96c38b2%2F'},
		// {start:'2024-05-16 00:00',end:'2024-05-28 00:00',shop:'rakuten',msg:'10%OFFクーポン(パンパース)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F8890221edf850ab496003308835cdfc64f995a71899a08c7%2F'},
		// {start:'2024-02-16 10:00',end:'2024-05-30 23:59',shop:'rakuten',msg:'10%OFFクーポン(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F801ef880793535926049cd17bae0edb8a26208d98e720ad1%2F'},
		// {start:'2024-02-16 10:00',end:'2024-06-11 01:59',shop:'rakuten',msg:'300円OFFクーポン(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F22936559e5a5b5622e7203de5360c48e9247f9e0bcc2f092%2F'},
		// {start:'2024-02-16 10:00',end:'2024-09-11 23:59',shop:'rakuten',msg:'500円OFFクーポン(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F302176b6e263ea28560869c1266e4b0816aa8209b55307e0%2F'},
		// {start:'2024-02-16 10:00',end:'2024-09-11 23:59',shop:'rakuten',msg:'600円OFFクーポン(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F1f4e391726473a34b6bae04aeefcd7b3882340f1dc96b57f%2F'},
		// {start:'2024-02-16 10:00',end:'2024-06-11 01:59',shop:'rakuten',msg:'700円OFFクーポン(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe51d261bb79cc845d36a29035bf077ece00d5e60cf723e0d%2F'},
		// {start:'2024-05-16 00:00',end:'2024-09-11 09:59',shop:'rakuten',msg:'10%OFFクーポン(ムーニー)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fb11be32147dce71b99c60bd8e3d716907e0e2d5bb0abc18b%2F'},
		// {start:'2024-06-24 00:00',end:'2024-06-25 23:59',shop:'rakuten',msg:'最大1,000円OFFクーポン(ベビーザらス)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fpoint-g.rakuten.co.jp%2Fcampaign%2Fpickup%2Fichiba_oneday_coupon%2F20240625_1000%2F'},
		// {start:'2024-05-16 10:00',end:'2024-06-30 23:59',shop:'rakuten',msg:'900円OFFクーポン(日用品)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fgenre%2Fdaily%2Fcoupon%2F2406%2F'},
		{start:'2024-09-19 10:00',end:'2024-09-30 09:59',shop:'rakuten',msg:'＋4倍ポイント(日用品)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fgenre%2Fdaily%2F'},
		// {start:'2024-05-16 10:00',end:'2024-07-31 23:59',shop:'rakuten',msg:'2,000円OFFクーポン(グーンプラス肌の贅沢)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F65dc62c9ac39cc8822b22f7b5a6d68c26a441a0795b5efd7%2F'},
		// {start:'2024-05-16 10:00',end:'2024-07-31 23:59',shop:'rakuten',msg:'1,000円OFFクーポン(グーンまっさらさら通気)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fe8ecdbdba0454c453034095f8b0ebdc2cbba70ba79c6c908%2F'},
		// {start:'2024-05-16 10:00',end:'2024-07-31 23:59',shop:'rakuten',msg:'1,000円OFFクーポン(グーンまっさらさら通気[旧])',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F26043bbbc0c3d244f63a0b218305b4188f469a55e46d1f32%2F'},
		// {start:'2024-05-16 10:00',end:'2024-06-28 23:59',shop:'rakuten',msg:'10%OFFクーポン(グーンおしりふき)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Faf0b93c51733c02f6d01fbac1e342270d637c345735dc359%2F'},
		// {start:'2024-05-16 10:00',end:'2024-07-31 23:59',shop:'rakuten',msg:'50円OFFクーポン',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F6e7fe9d5b316595955fb45ba2976e796aceec76a5f1e98d3%2F'},
		// {start:'2024-05-16 10:00',end:'2024-07-04 23:59',shop:'rakuten',msg:'15%OFFクーポン',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2Fb340fa40d1e9d57d51b1cdf3b78b510e58b4a0ad8992e07d%2F'},
		// {start:'2024-08-17 10:00',end:'2024-08-24 23:59',shop:'rakuten',msg:'15%OFFクーポン(8/24 20:00-23:59)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FgetCoupon%3Fgetkey%3DUVhYUi1SVjhCLVdYQ0YtVVVUSw--%26rt%3D'},
		// {start:'2024-05-16 10:00',end:'2024-07-31 23:59',shop:'rakuten',msg:'15%OFFクーポン(オムツ)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F7eaa61014d9a39dbde9008890388f4f4d4382aa7861b44ff%2F'},
		// {start:'2024-05-16 10:00',end:'2024-08-01 23:59',shop:'rakuten',msg:'10%OFFクーポン',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fcoupon.rakuten.co.jp%2FdetailCoupon%2F03f8f8d7adf83b5923553f96669477e7cd82dbe4e62dcb53%2F'},
		// {start:'2024-07-18 00:00',end:'2024-08-19 09:59',shop:'rakuten',msg:'＋9倍ポイント(カテゴリー買いまわり)',url:'//af.moshimo.com/af/c/click?a_id=3393900&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fevent.rakuten.co.jp%2Fbrand%2Fcross-genre%2F'},
	
		// Amazon
		// {start:'2024-02-28 10:00',end:'2024-03-04 23:59',shop:'amazon',msg:'除菌シート実質無料',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D8416996051'},
		// {start:'2024-02-28 10:00',end:'2024-03-15 23:59',shop:'amazon',msg:'おやすみパンツ実質無料(パンパース購入)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D24003587051'},
		{start:'2024-04-01 10:00',end:'2025-03-31 23:59',shop:'amazon',msg:'10%OFFクーポンコード(グーンプラス)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D24578457051'},
		// {start:'2024-02-28 10:00',end:'2024-05-30 23:59',shop:'amazon',msg:'10%OFFまとめ割(花王3点以上まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3U86CS615K1J5'},
		// {start:'2024-02-28 10:00',end:'2024-05-12 23:59',shop:'amazon',msg:'出産準備アイテム購入5%ポイント還元',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fie%3DUTF8%26node%3D14248746051'},
		// {start:'2024-02-28 09:00',end:'2024-04-01 23:59',shop:'amazon',msg:'新生活セールFINAL',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fevents%2Fmonthlydealevent'},
		{start:'2024-09-01 00:00',end:'2024-12-20 23:59',shop:'amazon',msg:'最大9,800ポイント(らくらくベビー)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fnode%3D8489492051'},
		{start:'2024-09-06 00:00',end:'2024-10-06 23:59',shop:'amazon',msg:'10%OFFまとめ割(エリエール2点以上まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FAD4YNO3RW9TPC'},
		{start:'2024-09-05 00:00',end:'2024-09-30 23:59',shop:'amazon',msg:'1,000円OFF(花王 5,000円以上まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA2OPB6DKARAIE9'},
		{start:'2024-09-05 00:00',end:'2024-09-18 23:59',shop:'amazon',msg:'1,000円OFF(P＆G 5,000円以上まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA1I0630R4ZG2HY'},
		// {start:'2024-02-28 00:00',end:'2024-04-18 23:59',shop:'amazon',msg:'5%OFFまとめ割(児童書まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse%3Fie%3DUTF8%26node%3D24562174051'},
		// {start:'2024-02-28 00:00',end:'2024-04-15 23:59',shop:'amazon',msg:'10%ポイント還元(メリーズ)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D24578454051'},
		// {start:'2024-08-28 09:00',end:'2024-09-04 23:59',shop:'amazon',msg:'スマイルSALE',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fevents%2Fmonthlydealevent'},
		// {start:'2024-04-30 00:00',end:'2024-06-30 23:59',shop:'amazon',msg:'5%OFF(母の日・父の日2点まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D19188185051%26field-enc-merchantbin%3DAN1VRQENFRJN5'},
		{start:'2024-09-01 00:00',end:'2024-10-31 23:59',shop:'amazon',msg:'5%OFF(秋の2点まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D21311306051'},
		// {start:'2024-07-17 00:00',end:'2024-08-31 23:59',shop:'amazon',msg:'5%OFF(ベビー関連2点まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fpromotion%2Fpsp%2FA3ICNOXQ82K3KQ'},
		// {start:'2024-04-30 00:00',end:'2024-06-30 23:59',shop:'amazon',msg:'おやすみパンツ実質無料',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fs%3Frh%3Dn%253A24003587051'},
		// {start:'2024-04-30 00:00',end:'2024-06-13 23:59',shop:'amazon',msg:'10%OFF(花王・サントリーまとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D25902331051'},
		// {start:'2024-04-30 00:00',end:'2024-05-19 23:59',shop:'amazon',msg:'10%OFFまとめ割(レック2点以上まとめ買い)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fgp%2Fbrowse.html%3Fnode%3D24818975051'},
		{start:'2024-05-13 00:00',end:'2024-12-31 23:59',shop:'amazon',msg:'最大1,700ポイント(らくらくベビー リピート・サイズアップ)',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fb%3Fnode%3D24555597051'},
		// {start:'2024-07-10 00:00',end:'2024-07-15 23:59',shop:'amazon',msg:'プライムデー先行セール',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fprimeday'},
		// {start:'2024-07-15 00:00',end:'2024-07-17 23:59',shop:'amazon',msg:'プライムデー',url:'//af.moshimo.com/af/c/click?a_id=765060&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fprimeday'},
		{start:'2024-09-20 09:00',end:'2024-09-24 23:59',shop:'amazon',msg:'秋のお出かけタイムセール祭',url:'https://www.amazon.co.jp/b/ref_%253Dac_topban_fde?node=8514009051&linkCode=ll2&tag=gj7masawo00-22&linkId=8d7bac2624bc9694430b06d6820b2c03&language=ja_JP&ref_=as_li_ss_tl'},

		// アカチャンホンポ
		{start:'2024-08-30 00:00',end:'2024-09-26 23:59',shop:'akachan',msg:'月間紙おむつSALE',url:`https://chirashi.akachan.jp/ad/baby/diaper2409/`},
		// {start:'2024-09-06 00:00',end:'2024-09-13 23:59',shop:'akachan',msg:'アカトク',url:`https://chirashi.akachan.jp/ad/special/akatoku/index.html`},
		// {start:'2024-02-28 10:00',end:'2024-03-29 09:59',shop:'akachan',msg:'最大400円OFFクーポン(やさしいGenki!購入)',url:`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rgenki/')}`},
		// {start:'2024-02-28 10:00',end:'2024-04-01 09:59',shop:'akachan',msg:'最大200円OFFクーポン(メリーズ購入)',url:`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rcpnmrs-1/')}`},
		// {start:'2024-05-18 10:00',end:'2024-05-19 09:59',shop:'akachan',msg:'ポイント5倍(8のつく日)',url:`https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884232649&vc_url=${encodeURIComponent('https://shop.akachan.jp/shop/r/rse90/#3de10p')}`},

		// LOHACO
		// {start:'2024-02-28 10:00',end:'2024-03-19 12:00',shop:'lohaco',msg:'10%OFFクーポン(3,850円以上購入)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/NzRhZDcyZWVjOTA2OGVmMDU3NDg1ZjE3NTBl/')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 12:00',shop:'lohaco',msg:'10%OFFクーポン(パンパース)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MzI2ZGZmM2ZiM2Y1YjIzMmVjNTVmM2FhNjY5')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 23:59',shop:'lohaco',msg:'LYPプレミアム登録で1万円相当',url:'//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=890207585'},
		// {start:'2024-06-14 00:00',end:'2024-06-15 23:59',shop:'lohaco',msg:'PayPay＋4%ポイント(HAPPYDAY)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/special/event/happyday_entry/')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-21 12:00',shop:'lohaco',msg:'20%OFFクーポン(ムーニーマン)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MjA0ZjNjMTM0ZGIyMDg1ZDM4NjE1YTJlOTli/')}`},
		// {start:'2024-02-28 10:00',end:'2024-05-27 12:00',shop:'lohaco',msg:'10%OFFクーポン(エリエール)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MTAwYzMzZWI4ZmEyMDU4NjRiZjM0MzA5MzIx/')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 12:00',shop:'lohaco',msg:'15%OFFクーポン(メリーズファースト)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/YTdmZmNhZGQxNmZiYTYwMGE4ZjY1M2U4N2Nk')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 23:00',shop:'lohaco',msg:'15%OFFクーポン(P&G)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/coupon/others/MzI2ZGZmM2ZiM2Y1YjIzMmVjNTVmM2FhNjY5/')}`},
		{start:'2024-09-22 00:00',end:'2024-09-22 23:59',shop:'lohaco',msg:'3%OFFクーポン(ゾロ目の日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/coupon/zorome/')}`},
		{start:'2024-09-25 00:00',end:'2024-09-25 23:59',shop:'lohaco',msg:'＋4%商品券(5のつく日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/5day/')}`},
		// {start:'2024-04-28 00:00',end:'2024-04-28 23:59',shop:'lohaco',msg:'PayPay＋4%ポイント(買う！買う！サンデー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/cowcow/')}`},
		{start:'2024-09-29 00:00',end:'2024-09-29 23:59',shop:'lohaco',msg:'PayPay＋5%ポイント(プレミアムな日曜日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/lypsunday/')}`},
		// {start:'2024-08-24 00:00',end:'2024-08-25 23:59',shop:'lohaco',msg:'PayPay＋5%ポイント(日曜日は誰でも)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884217470&vc_url=${encodeURIComponent('https://lohaco.yahoo.co.jp/special/event/paypaycp_entry/')}`},
		// {start:'2024-05-01 00:00',end:'2024-05-26 23:59',shop:'lohaco',msg:'PayPay＋10%キャンペーン(エリエール)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/daio_paper/')}`},
		// {start:'2024-02-28 00:00',end:'2024-03-31 23:59',shop:'lohaco',msg:'PayPay＋15%キャンペーン(パンパース)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/pg/')}`},
		{start:'2024-10-01 00:00',end:'2024-10-01 23:59',shop:'lohaco',msg:'PayPay＋3%ポイント(ファーストデー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/firstday/')}`},
		// {start:'2024-08-14 00:00',end:'2024-08-17 23:59',shop:'lohaco',msg:'PayPay＋9%ポイント(ストアラリー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/storerally/')}`},
		// {start:'2024-04-10 00:00',end:'2024-04-17 23:59',shop:'lohaco',msg:'PayPay＋4%ポイント(ボーナスストア)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/allbonus/')}`},
		// {start:'2024-04-10 00:00',end:'2024-05-19 23:59',shop:'lohaco',msg:'PayPay＋6%ポイント(ビッグボーナス)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/sale/bigbonus/')}`},

		// Yahoo！
		// {start:'2024-02-28 10:00',end:'2024-03-31 23:59',shop:'yahoo',msg:'LYPプレミアム登録で1万円相当',url:'//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=890207585'},
		// {start:'2024-02-28 10:00',end:'2024-03-31 23:59',shop:'yahoo',msg:'10%OFFクーポン(itsumomart)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/interior/NDBmNjE1Nzc1YjE5YTdhMTE5YWUzZTgwOTRj')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 23:59',shop:'yahoo',msg:'10%OFFクーポン(ひかりTV)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/others/YjVlZjUxOTYwNDYwOWMyNGI5ZDc4YTMzMDJm')}`},
		{start:'2024-09-25 00:00',end:'2024-09-25 23:59',shop:'yahoo',msg:'＋4%商品券(5のつく日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/5day/')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-03 23:59',shop:'yahoo',msg:'100円OFFクーポン(ひかりTV)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/kaden/ZWYyZjhhMjIyODYyOWE3YWRiZGIyZjgyZjk5')}`},
		// {start:'2024-04-28 00:00',end:'2024-04-28 23:59',shop:'yahoo',msg:'PayPay＋4%ポイント(買う！買う！サンデー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/cowcow/')}`},
		{start:'2024-09-29 00:00',end:'2024-09-29 23:59',shop:'yahoo',msg:'PayPay＋5%ポイント(プレミアムな日曜日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/lypsunday/')}`},
		{start:'2024-09-22 00:00',end:'2024-09-22 23:59',shop:'yahoo',msg:'3%OFFクーポン(ゾロ目の日)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/coupon/zorome/')}`},
		// {start:'2024-02-28 00:00',end:'2024-03-31 23:59',shop:'yahoo',msg:'PayPay＋15%キャンペーン(パンパース)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/gateway/brand/pg/')}`},
		{start:'2024-10-01 00:00',end:'2024-10-01 23:59',shop:'yahoo',msg:'PayPay＋3%ポイント(ファーストデー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/firstday/')}`},
		// {start:'2024-08-14 00:00',end:'2024-08-17 23:59',shop:'yahoo',msg:'PayPay＋9%ポイント(ストアラリー)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/storerally/')}`},
		// {start:'2024-04-10 00:00',end:'2024-04-17 23:59',shop:'yahoo',msg:'PayPay＋4%ポイント(ボーナスストア)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/campaign/allbonus/')}`},
		// {start:'2024-04-10 00:00',end:'2024-05-19 23:59',shop:'yahoo',msg:'PayPay＋6%ポイント(ビッグボーナス)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/promotion/sale/bigbonus/')}`},
		// {start:'2024-08-02 19:00',end:'2024-08-19 15:00',shop:'yahoo',msg:'10%OFFクーポン(メガストア)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/others/YmQ5OWY1YjUwNDAxY2YxZTgwYTAwZjZjNjM2')}`},
		// {start:'2024-08-09 20:00',end:'2024-08-18 23:59',shop:'yahoo',msg:'10%OFFクーポン(アイリスプラザ)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://shopping.yahoo.co.jp/coupon/baby/YTAxZjc3ZGRjYThhNDI3MGFhMjdmZTFkZmI0')}`},

		// au PAY
		// {start:'2024-02-28 10:00',end:'2024-03-01 09:59',shop:'aupay',msg:'300円OFFクーポン(コジマ5,000円以上購入)',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/bep/m/coup02?tf=couponInfo91&coupon_keys=%20ds_24_2_smpre_monthly_pobvsq%20&spe_id=prom686943931&spe_id=prom694441080')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-31 09:59',shop:'aupay',msg:'ポイント倍々キャンペーン＋200円OFFクーポン',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/event/multibuy/index.html')}`},
		{start:'2024-09-23 00:00',end:'2024-09-23 23:59',shop:'aupay',msg:'最大33%ポイント還元(三太郎の日)',url:'https://click.linksynergy.com/fs-bin/click?id=9Y6G27xjoU8&offerid=1122737.77&type=3&subid=0'},
		// {start:'2024-05-01 10:00',end:'2024-05-11 09:59',shop:'aupay',msg:'ポイント超超祭',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=46164&murl=${encodeURIComponent('https://wowma.jp/event/cho2_festival/index.html')}`},
		// {start:'2024-02-28 10:00',end:'2024-05-13 23:59',shop:'aupay',msg:'1,000円OFFクーポン(13,000円以上購入)',url:`https://click.linksynergy.com/fs-bin/click?id=9Y6G27xjoU8&offerid=1122737.299&type=3&subid=0`},

		// ベビーザらス
		// {start:'2024-07-26 10:00',end:'2024-08-19 23:59',shop:'babiesrus',msg:'夏のおでかけ＆帰省準備応援！まとめ買いセール',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www.toysrus.co.jp/promo/CSfbaby_dm.jsp')}`},
		// {start:'2024-02-28 10:00',end:'2024-03-18 23:59',shop:'babiesrus',msg:'ベビーザらスフェア',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www.toysrus.co.jp/f/CSfSale_b.jsp')}`},
		{start:'2024-09-18 00:00',end:'2024-09-18 23:59',shop:'babiesrus',msg:'パンパース ポイント10倍(8のつく日)',url:`https://click.linksynergy.com/deeplink?id=9Y6G27xjoU8&mid=2831&murl=${encodeURIComponent('https://www.babiesrus.co.jp/ja-jp/events/event-92.html')}`},

		// 西松屋
		// {start:'2024-08-29 00:00',end:'2024-09-10 23:59',shop:'nishimatsuya',msg:'チラシセール',url:'https://sale.24028.jp/digital-chirashi/period-0829-0910/'},
		// {start:'2024-08-29 00:00',end:'2024-09-10 23:59',shop:'nishimatsuya',msg:'子育て応援SALE',url:'https://sale.24028.jp/web-chirashi/period-0829-0910_ouen/'},

		// P&G
		// {start:'2024-02-28 10:00',end:'2024-05-15 23:59',shop:'pg',msg:'PayPay20%(パンパース3,000円以上)',url:`//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3306039&pid=884215984&vc_url=${encodeURIComponent('https://toku.yahoo.co.jp/ysprgpampers202404/entry')}`},

		// コストコ
		// {start:'2024-08-03 00:00',end:'2024-08-08 23:59',shop:'costco',msg:'ベビーフェア',url:'https://cds2.costcojapan.jp/cds/mail-images/upz/240801_qkpg/thu01d/pc_index.html'},

		// ツルハ
		// {start:'2024-08-01 00:00',end:'2024-08-18 23:59',shop:'tsuruha',msg:'30%ポイント(HAPPAY×P＆G)',url:'https://www.tsuruha.co.jp/campaign/HAPPAY_20240801/'},
		// {start:'2024-08-16 00:00',end:'2024-08-18 23:59',shop:'tsuruha',msg:'全品送料無料・ポイント7倍',url:'https://shop.tsuruha.co.jp/'},
		// {start:'2024-08-09 00:00',end:'2024-08-12 23:59',shop:'tsuruha',msg:'全品10%OFFクーポン',url:'https://shop.tsuruha.co.jp/t82-20240812-10off'},

	]

	campaigns.map((campaign) =>{
		if((shopId !== 'all') && (shopId !== campaign.shop)){
			return
		}

		if(isCampaign(campaign.start, campaign.end)){
			shops[campaign.shop].push({
				start:campaign.start.slice(),
				end:campaign.end.slice(),
				url:campaign.url.slice(),
				msg:`[${formatDate(campaign.start, campaign.end)}] ${campaign.msg.slice()}`,
			})
			return
		}
		else if(isBeforeCampaign(campaign.start)){
			_shops[campaign.shop].push({
				start:campaign.start.slice(),
				end:campaign.end.slice(),
				url:campaign.url.slice(),
				msg:`[${formatDateBefore(campaign.start, campaign.end)}] ${campaign.msg.slice()}`,
			})
			return
		}
		else{
			return
		}
	})

	// 開催中のキャンペーンを終了日が早い順に並び替える
	const keys = Object.keys(shops);
	keys.forEach((key) => {
		shops[key].sort((a, b) => new Date(a.end) - new Date(b.end));
	});


	// 開催予定のキャンペーンを開始日が早い順に並び替える
	const _keys = Object.keys(_shops);
	_keys.forEach((key) => {
		_shops[key].sort((a, b) => new Date(a.start) - new Date(b.start));
	});

	const ShopInfo = ({ shopData, shopId, shopName }) => (
		<>
			{shopData.map((info, index) => (
				<>
					{index === 0 && (
						<p id={shopId} style={{fontSize:`0.7rem`, color: `#363636`, marginBottom:`0rem`}}>
							<strong>{shopName}</strong>
						</p>
					)}
					<div style={{marginTop: `0.5rem`}}></div>
					<div className="columns is-mobile">
						<div className="column" style={{paddingTop: `0.3rem`, paddingBottom: `0.3rem`}}>
							<FiInfo />&nbsp;&nbsp;
							<a href={info.url} rel="nofollow" style={{fontSize:`0.75rem`, textDecoration:`none`}}>{info.msg}</a>
						</div>
					</div>
				</>
			))}
		</>
	);
	
	return (
	<div className="columns is-multiline is-gapless">
		<div className="column is-half is-vcentered">
			<div class="table-container">
				<article className="message is-small" style={{ marginBottom: `0.0rem`, marginLeft: `1px`, marginRight: `1px`, backgroundColor: `#ffffff` }}>
					<div className="message-header">
						<p>{`現在開催中のキャンペーン`}</p>
					</div>
					<div className="message-body">
						<ShopInfo shopData={shops['amazon']} shopId={'amazon'} shopName={`Amazon`} />
						<ShopInfo shopData={shops['rakuten']} shopId={'rakuten'} shopName={`楽天`} />
						<ShopInfo shopData={shops['lohaco']} shopId={'lohaco'} shopName={`LOHACO`} />
						<ShopInfo shopData={shops['yahoo']} shopId={'yahoo'} shopName={`ヤフショ`} />
						<ShopInfo shopData={shops['aupay']} shopId={'aupay'} shopName={`au PAY マーケット`} />
						<ShopInfo shopData={shops['akachan']} shopId={'akachan'} shopName={`アカチャンホンポ`} />
						<ShopInfo shopData={shops['babiesrus']} shopId={'babiesrus'} shopName={`ベビーザらス`} />
						<ShopInfo shopData={shops['nishimatsuya']} shopId={'nishimatsuya'} shopName={`西松屋`} />
						<ShopInfo shopData={shops['pg']} shopId={'pg'} shopName={`P&G`} />
						<ShopInfo shopData={shops['costco']} shopId={'costco'} shopName={`コストコ`} />
						<ShopInfo shopData={shops['tsuruha']} shopId={'tsuruha'} shopName={`ツルハ`} />
					</div>
				</article>
			</div>
		</div>
		
		<div className="column is-half is-vcentered">
			<div class="table-container">
				<article className="message is-small" style={{ marginBottom: `0.0rem`, marginLeft: `1px`, marginRight: `1px`, backgroundColor: `#ffffff` }}>
					<div className="message-header">
						<p>{`今後開催予定のキャンペーン`}</p>
					</div>
					<div className="message-body">
						<ShopInfo shopData={_shops['amazon']} shopId={'_amazon'} shopName={`Amazon`} />
						<ShopInfo shopData={_shops['rakuten']} shopId={'_rakuten'} shopName={`楽天`} />
						<ShopInfo shopData={_shops['lohaco']} shopId={'_lohaco'} shopName={`LOHACO`} />
						<ShopInfo shopData={_shops['yahoo']} shopId={'_yahoo'} shopName={`ヤフショ`} />
						<ShopInfo shopData={_shops['aupay']} shopId={'_aupay'} shopName={`au PAY マーケット`} />
						<ShopInfo shopData={_shops['akachan']} shopId={'_akachan'} shopName={`アカチャンホンポ`} />
						<ShopInfo shopData={_shops['babiesrus']} shopId={'_babiesrus'} shopName={`ベビーザらス`} />
						<ShopInfo shopData={_shops['nishimatsuya']} shopId={'_nishimatsuya'} shopName={`西松屋`} />
						<ShopInfo shopData={_shops['pg']} shopId={'_pg'} shopName={`P&G`} />
						<ShopInfo shopData={_shops['costco']} shopId={'_costco'} shopName={`コストコ`} />
						<ShopInfo shopData={_shops['tsuruha']} shopId={'_tsuruha'} shopName={`ツルハ`} />
					</div>
				</article>
				</div>
			</div>
		</div>
	);
}

Shop.propTypes = {
  shopId: PropTypes.string,
}

function isCampaign(startDateTime, endDateTime) {
	const now = moment();
	const start = moment(startDateTime, 'YYYY-MM-DD HH:mm');
	const end = moment(endDateTime, 'YYYY-MM-DD HH:mm');
	return now.isBetween(start, end, 'minute', '[]');
}

function isBeforeCampaign(startDateTime) {
	const now = moment();
	const start = moment(startDateTime, 'YYYY-MM-DD HH:mm');
	const oneWeekBeforeStart = start.clone().subtract(1, 'weeks');

	return now.isAfter(oneWeekBeforeStart) && now.isBefore(start);
}

function formatDate(startDateTime, endDateTime) {
	const start = moment(startDateTime, 'YYYY-MM-DD HH:mm');
	const end = moment(endDateTime, 'YYYY-MM-DD HH:mm');

	// 開始日時と終了日時が同じ日であるかどうかを確認
	if (start.isSame(end, 'day')) {
			// 同じ日の場合は「M/DD」の形式で返す
			return start.format(' MM/DD ');
	} else {
			// 異なる日の場合は終了日を「~M/DD」の形式で返す
			return `~${end.format('MM/DD')} `;
	}
}

function formatDateBefore(startDateTime, endDateTime) {
	const start = moment(startDateTime, 'YYYY-MM-DD HH:mm');
	const end = moment(endDateTime, 'YYYY-MM-DD HH:mm');

	// 開始日時と終了日時が同じ日であるかどうかを確認
	if (start.isSame(end, 'day')) {
			// 同じ日の場合は「M/DD」の形式で返す
			return start.format(' MM/DD ');
	} else {
			// 異なる日の場合は開始日を「M/DD~」の形式で返す
			return ` ${start.format('MM/DD')}~`;
	}
}

export default Shop
